<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h3>Integrations</h3>
      </v-col>
    </v-row>
    <v-divider class="mb-5"></v-divider>
    <v-select
      :items="users"
      label="Timetastic User"
      v-model="timetasticUserId"
      outlined
      item-value="id"
      item-text="title"
      :loading="loading"
      v-if="hasTimetasticIntegration"
      @change="attachTimetasticUser"
    ></v-select>
  </div>
</template>

<script>
import { appHasIntegration } from "@/utils";

export default {
  props: {
    teamMember: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      timetasticUserId: null,
      hasTimetasticIntegration: false,
    };
  },

  created() {
    this.timetasticUserId = this.teamMember.timetastic_user_id;
  },

  mounted() {
    this.hasTimetasticIntegration = appHasIntegration(
      "timetastic",
      this.$route.params.id
    );

    if (this.hasTimetasticIntegration) {
      this.loadUsers();
    }
  },

  computed: {
    teamMembers() {
      return this.$store.getters["team/all"];
    },

    users() {
      const users = this.$store.getters["team/timetastic/all"];

      return users.map(u => {
        const member = this.teamMembers.find(
          m => m.timetastic_user_id === parseInt(u.id)
        );
        const disabled =
          member == null
            ? false
            : parseInt(this.teamMember.timetastic_user_id) === parseInt(u.id)
            ? false
            : true;

        return {
          id: u.id,
          title: u.firstname + " " + u.surname + " (ID: " + u.id + ")",
          disabled,
        };
      });
    },
  },

  methods: {
    loadUsers() {
      this.loading = true;

      this.$store
        .dispatch("team/timetastic/loadUsers", {
          appId: this.$route.params.id,
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getName(item) {
      return `${item.firstname} ${item.surname} (ID: ${item.id})`;
    },
    attachTimetasticUser() {
      this.loading = true;

      this.$store
        .dispatch("team/timetastic/updateUser", {
          appId: this.$route.params.id,
          userId: this.$route.params.teamMemberId,
          timetasticUserId: this.timetasticUserId,
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
